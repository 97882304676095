import React, {Component, Fragment} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {SERVER_PATH, TABLE_ID} from '../../config/constants';

import './styles.scss';

const TableHeader = () => {
    return (
        <div className='inputRow'>
            <Grid container spacing={3}>
                <Grid item xs={5} />
                <Grid item xs={4}>
                    <i>МСК Сыктывкарская</i>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <b>Инвентарный номер ТП</b>
                </Grid>
                <Grid item xs={3}>
                    <b>№ поворотной точки</b>
                </Grid>
                <Grid item xs={2}>
                    <b>X</b>
                </Grid>
                <Grid item xs={2}>
                    <b>Y</b>
                </Grid>
            </Grid>
        </div>
    )
};

const RenderRow = ({
    mskX,
    mskY,
    wgsX,
    wgsY,
    rotpoint,
    onChange,
}) => {
    const handleChange = (key, val) => {
        let rez = {
            rotpoint: rotpoint,
            mskX: mskX,
            mskY: mskY,
            wgsX: wgsX,
            wgsY: wgsY,
        };

        let _val = val;

        if (key !== 'num') {
            _val = val.replace(/\s+/, "");

            try {
                let inputValue = parseFloat(val);
                if (!isNaN(inputValue)) {
                    _val = inputValue;
                } else if (val.length === 0) {
                    _val = val;
                } else {
                    alert('Введено некорректно значение: ', val);
                }
            } catch (e) {
                console.error(e);
            }
        }

        switch(key) {
            case 'num':
            default:
                rez.num = _val;
                break;
            case 'mskX':
                rez.mskX = _val;
                break;
            case 'mskY':
                rez.mskY = _val;
                break;
            case 'rotpoint':
                rez.rotpoint = _val;
                break;
        }

        onChange(rez);
    };

    return (
        <div className='dataRow'>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <div className='tpItem'>
                        <TextField 
                            label="(по умолч. от 1 до 4)" 
                            type="number"
                            variant="outlined"
                            value={rotpoint}
                            onChange={e => handleChange('rotpoint', e.target.value)}
                            disabled
                        />
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        label="МСК X" 
                        type="number"
                        variant="outlined"
                        value={mskX}
                        onChange={e => handleChange('mskX', e.target.value)}
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        label="МСК Y" 
                        type="number"
                        variant="outlined"
                        value={mskY}
                        onChange={e => handleChange('mskY', e.target.value)}
                        required
                    />
                </Grid>
            </Grid>
        </div>
    );
};

const TableRow = ({
    id,
    num,
    data,
    onChange,
}) => {
    const handleChange = (type, val) => {
        if (type === 'data') {
            let tmpData = [ ...data ];
            const rotpoint = val.rotpoint - 1;

            if (tmpData[rotpoint]) {
                tmpData[rotpoint] = {
                    mskX: val.mskX,
                    mskY: val.mskY,
                    wgsX: val.wgsX,
                    wgsY: val.wgsY,
                };
            }
    
            onChange(id, {
                num: num,
                data: [ ...tmpData ],
            });
        } else {
            onChange(id, {
                num: val,
                data: data,
            });
        }
    };

    const handleRotateAdd = () => {
        let tmpData = [ ...data ];

        tmpData.push({
            mskX: '',
            mskY: '',
            wgsX: '',
            wgsY: '',
        });

        onChange(id, {
            num: num,
            data: [ ...tmpData ],
        });
    };

    return (
        <div className='inputRow'>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <div className='tpItem'>
                        <span>ТП </span>
                        <TextField 
                            label="№" 
                            type="text"
                            variant="outlined"
                            value={num}
                            onChange={e => handleChange('num', e.target.value)}
                        />
                    </div>
                </Grid>

                <Grid item xs={6}>
                    {
                        data.map((el, ind) => (
                            <RenderRow
                                key={ `${num}_${ind + 1}` }
                                mskX={ el.mskX }
                                mskY={ el.mskY }
                                wgsX={ el.wgsX }
                                wgsY={ el.wgsY }
                                rotpoint={ ind + 1 }
                                onChange={ val => handleChange('data', val) }
                            />
                        ))
                    }

                    <Button variant="contained" color="secondary" onClick={handleRotateAdd}>Добавить поворотную точку</Button>
                </Grid>
            </Grid>
        </div>
    )
};


class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            lastSave: null,
            currentPage: 1,
            pages: 1,
            data: {},
            editedList: {},
            search: '',
        };

        this.handleRowAdd = this.handleRowAdd.bind(this);
        this.handleRowChange = this.handleRowChange.bind(this);
        this.handleWGS = this.handleWGS.bind(this);
        this.saveData = this.saveData.bind(this);
        this.actualizeData = this.actualizeData.bind(this);
        this.handlePaginate = this.handlePaginate.bind(this);
    }

    actualizeData() {
        const {search} = this.state;
        let searchStr = `${SERVER_PATH}/tables?limit=10&page=${this.state.currentPage}&sortBy=num`;

        if (search) {
            searchStr = `${SERVER_PATH}/tables?limit=10&page=${this.state.currentPage}&sortBy=num&num=${search}`
        }

        fetch(searchStr, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.results) {
                    const rez = {};

                    result.results.forEach(el => {
                        rez[el.id] = {
                            num: el.num,
                            data: [...el.data],
                        };
                    });

                    this.setState({
                        data: rez,
                        pages: result.totalPages,
                        isLoading: false,
                    });
                }
            })
            .catch(error => console.log('error', error));
    }

    componentDidMount() {
        this.actualizeData();
    }

    async saveData() {
        if (this.state.isEdited) {
            const { editedList } = this.state;

            for (let el of Object.keys(editedList)) {
                try {
                    console.log(editedList[el]);
                    console.log(JSON.stringify(editedList[el]));
                    console.log(JSON.parse( JSON.stringify(editedList[el]) ))

                    let resp;

                    if (el.includes('new')) {
                        resp = await fetch(`${SERVER_PATH}/tables/`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(editedList[el]),
                        });
                    } else {
                        resp = await fetch(`${SERVER_PATH}/tables/${el}/`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(editedList[el]),
                        });
                    }

                    if (resp && !resp.ok) {
                        const message = `An error has occured: ${resp.status}`;
                        throw new Error(message);
                    }
                } catch (error) {
                    console.log('error', error);
                }
            }

            this.setState({
                isEdited: false,
                lastSave: new Date().toLocaleString(),
                editedList: {},
            }, () => {
                this.actualizeData();
            });
        }
    }

    handleRowAdd() {
        const count = Object.keys(this.state.data).length;

        this.setState({
            data: {
                ...this.state.data,
                [`new_${count + 1}`]: {
                    num: '', 
                    data: [
                        {mskX: '', mskY: '', wgsX: '', wgsY: ''}
                    ]
                },
            },
        });
    }

    handleRowChange(id, val) {
        console.log(id, val);

        this.setState({
            isEdited: true,
            data: {
                ...this.state.data,
                [id]: val,
            },
            editedList: {
                ...this.state.editedList,
                [id]: val,
            },
        });
    }

    handleWGS() {
        const data = this.state.data;

        let arr = [];
        let error = false;
        Object.keys(data).map(el => {
            if (data[el].mskX && data[el].mskY) {
                arr.push({
                    y: data[el].mskX,
                    x: data[el].mskY,
                });
            } else {
                error = true;
            }
        });

        if (!error) {
            fetch("https://coords.actusland.ru", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id: 1,
                    from: 'syktyvkar',
                    to: 'wgs84',
                    points: [...arr],
                }),
            })
                .then(response => response.json())
                .then(result => {
                    console.log(result);
 
                    let initState = data;
                    Object.keys(initState).map(el => {
                        initState[el] = {
                            ...initState[el],
                            wgsY: result.points[el - 1].x,
                            wgsX: result.points[el - 1].y,
                        };
                    });

                    this.setState({
                        data: initState,
                        isEdited: true,
                    });
                })
                .catch(error => console.log('error', error));
        } else {
            alert('В таблице на шаге №1 есть пустые незаполненные поля');
        }

    }

    handlePaginate(e, page) {
        this.setState({
            currentPage: page,
            isLoading: true,
            data: {},
        }, () => {
            this.actualizeData();
        });
    }

    render() {
        const {data, search, isLoading, pages, currentPage, lastSave, isEdited} = this.state;

        const handleSearch = (val) => {
            this.setState({
                search: val,
            }, () => {
                this.actualizeData();
            });
        };

        return (
            <Container fixed>
                <div className='wrap'>

                    <TextField 
                        label="Поиск по номеру ТП" 
                        variant="outlined"
                        className='coordsRow__input'
                        size="small"
                        value={search}
                        onChange={e => handleSearch(e.target.value)}
                    />

                    <Pagination count={pages} page={currentPage} siblingCount={8} onChange={this.handlePaginate} style={{marginBottom: 30}} />
                    <p className='infoMsg'>Не забудьте сохранить изменения в таблице!</p>

                    <Card variant="outlined" className='card'>
                        <CardContent>
                            <h2 className='title'>Заполните таблицу ниже (Коми)</h2>

                            <TableHeader />
                            {
                                (!isLoading && data) ? Object.keys(data).map(el => (
                                    <TableRow
                                        key={el}
                                        id={el}
                                        num={data[el].num}
                                        data={data[el].data}
                                        onChange={(id, val) => this.handleRowChange(id, val)}
                                    />
                                )) : <p style={{color: 'red'}}><b>Идёт загрузка...</b></p>
                            }

                            <div className='addBtn'>
                                <Button variant="contained" color="primary" onClick={this.handleRowAdd}>Добавить новый ТП</Button>
                            </div>
    
                            {
                                (pages > 1) && !isLoading ? (
                                    <Fragment>
                                        <Pagination count={pages} page={currentPage} siblingCount={8} onChange={this.handlePaginate} style={{marginTop: 30}} />
                                        <p className='infoMsg'>Не забудьте сохранить изменения в таблице!</p>
                                    </Fragment>
                                ) : null
                            }

                            {
                                isEdited ? (
                                    <div className='saveZone'>
                                        <p className='saveZone__info'>Не забудьте сохранить изменения</p>
                                        
                                        <Button className='saveZone__btn' variant="contained" color="secondary" onClick={this.saveData}>Сохранить изменения</Button>
                                        
                                        {
                                            lastSave ? (
                                                <p className='saveZone__stat'>Последнее сохранение {lastSave}</p>
                                            ) : null
                                        }
                                    </div>
                                ) : null
                            }
                        </CardContent>
                    </Card>
                    
                </div>
                
            </Container>
        );
    }
}

export default HomePage;
