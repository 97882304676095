import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePage from '../../pages/HomePage';
import LinesPage from '../../pages/LinesPage';
import DemoPage from '../../pages/DemoPage';
import '../../assets/styles.scss';


class App extends Component {
    state = {

    }

    componentDidMount() {
    }

    render() {
        return (
          <Fragment>
            <Switch>
              <Route exact path='/' component={DemoPage} />
              <Route path='/tp' component={HomePage} />
            </Switch>
          </Fragment>
        );
    }
}

export default App;
