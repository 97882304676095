import React, {Component, useState, useEffect} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {SERVER_PATH, TABLE_ID} from '../../config/constants';
import './styles.scss';
import { filter } from 'lodash';


const LineHeader = () => (
    <div className='lineRow'>
        <Grid container spacing={3}>
            <Grid item xs={1}>
                <i>№ п/п</i>
            </Grid>
            <Grid item xs={1}>
                <i>№ по договору</i>
            </Grid>
            <Grid item xs={4}>
                <i>Наименование</i>
            </Grid>
            <Grid item xs={6}>
                <i>Структура</i>
            </Grid>
        </Grid>
    </div>
);

const CoordsLine = ({index = 1, X = 0, Y = 0, onChange}) => {
    const [_X, setX] = useState(X);
    const [_Y, setY] = useState(Y);

    useEffect(() => {
        setX(X);
        setY(Y);
    }, [X, Y]);

    const handleCoordsChange = (key, val) => {
        let _val = val.replace(/\s+/, "");

        try {
            let inputValue = parseFloat(val);

            if (!isNaN(inputValue)) {
                _val = inputValue;
            } else if (val.length === 0) {
                _val = val;
            } else {
                alert('Введено некорректно значение: ', val);
            }

            if (key === 'x') {
                onChange('x', _val);
            } else {
                onChange('y', _val);
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Grid container spacing={2} className='coordsRow'>
            <span className='coordsIndex'>{index}: </span>
            <Grid item xs={3}>
                <TextField 
                    label="X" 
                    type="number"
                    variant="outlined"
                    size="small"
                    value={_X}
                    onChange={e => handleCoordsChange('x', e.target.value)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label="Y" 
                    type="number"
                    variant="outlined"
                    size="small"
                    value={_Y}
                    onChange={e => handleCoordsChange('y', e.target.value)}
                />
            </Grid>
        </Grid>
    )
};

const LineElement = ({id, TPlist, N_pp, N_dog, Name, Inv_number, Coords = [
    {X: 0, Y: 0}
], onChange}) => {
    const [coordsData, setCoordsData] = useState(Coords);
    const [tp, setTP] = useState();

    const handleAddCoords = () => {
        setCoordsData([
            ...coordsData,
            {X: 0, Y: 0},
        ]);
    };

    const handleUpdate = (element, flag, value) => {
        const tmp = [ ...coordsData ];
        if (tmp[element]) {
            if (flag === 'x') {
                tmp[element].X = value;
            } else {
                tmp[element].Y = value;
            }

            setCoordsData(tmp);
            onChange(id, coordsData);
        }
    };

    useEffect(() => {
        if (TPlist && TPlist.length) {
            setTP(TPlist);
            console.log(TPlist);
        }
    }, [TPlist]);

    return (
        <div className='lineRow'>
            <Grid container spacing={3}>
                <Grid item xs={1}>
                    <strong>{N_pp}</strong>
                </Grid>
                <Grid item xs={1}>
                    {N_dog}
                </Grid>
                <Grid item xs={4}>
                    {Name}
                    <br/><br/>
                    <b style={{color: 'blue'}}>{Inv_number}</b>
                </Grid>
                <Grid item xs={6}>
                    <div className='coordsContainer'>
                        {
                            coordsData.map((el, ind) => (
                                <CoordsLine key={ind} X={el.X} Y={el.Y} onChange={(flag, val) => handleUpdate(ind, flag, val)} />
                            ))
                        }
                        <Button type="primary" onClick={ handleAddCoords }>Добавить ещё</Button>
                    </div>
                </Grid>
                {
                    /*
                        <Grid item xs={2}>
                            <Autocomplete
                                options={tp}
                                getOptionLabel={(option) => option.num.toString()}
                                renderInput={(params) => <TextField {...params} label="ТП №" variant="outlined" />}
                            />
                        </Grid>
                    */
                }
            </Grid>
        </div>
    )
};

class LinesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isEdited: false,
            lastSave: null,
            editedList: {},
            data: {},
            tpData: {},
            currentPage: 1,
            pages: 0,
        };

        this.saveData = this.saveData.bind(this);
        this.actualizeData = this.actualizeData.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handlePaginate = this.handlePaginate.bind(this);
    }

    actualizeData() {
        fetch(`${SERVER_PATH}/objects?limit=20&page=${this.state.currentPage}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.results) {
                    let data = result.results;
                    let rezObj = {};

                    data.forEach(el => {
                        rezObj[el.N_pp] = el;
                    });

                    console.log(rezObj);

                    this.setState({
                        data: rezObj,
                        pages: result.totalPages,
                        isLoading: false,
                    });
                }
            })
            .catch(error => console.log('error', error));
    }

    actualizeTP() {
        fetch(`${SERVER_PATH}/tables/${TABLE_ID}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.data) {
                    const data = result.data;
                    const rez = [];
                    let filtered = [];

                    if (data) {
                        Object.keys(data).forEach(el => {
                            rez.push(data[el]);
                        });

                        filtered = rez.filter((value, index, self) => {
                            return self.findIndex(v => v.num === value.num) === index;
                        });
                    }

                    this.setState({
                        tpData: filtered,
                    });
                }
            })
            .catch(error => console.log('error', error));
    }

    saveData() {
        if (this.state.isEdited) {
            const { editedList } = this.state;
            console.log(this.state.data);

            Object.keys(editedList).forEach(el => {
                fetch(`${SERVER_PATH}/objects/${el}/`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...editedList[el],
                    }),
                })
                    .then(response => response.json())
                    .then(result => {
                        console.log(result);
                        
                        this.setState({
                            isEdited: false,
                            lastSave: new Date().toLocaleString(),
                            editedList: {},
                        }, () => {
                            this.actualizeData();
                        });
                    })
                    .catch(error => console.log('error', error));
            });
        }
    }

    componentDidMount() {
        this.actualizeTP();
        this.actualizeData();

        setInterval(() => {
            this.saveData();
        }, 15000);
    }

    handleUpdate(id, element, newCoords) {
        this.setState({
            isEdited: true,
            editedList: {
                ...this.state.editedList,
                [id]: {
                    N_dog: element.N_dog,
                    N_pp: element.N_pp,
                    Name: element.Name,
                    Coords: newCoords,
                },
            },
        });
    }

    handlePaginate(e, page) {
        this.setState({
            currentPage: page,
            isLoading: true,
            data: {},
        }, () => {
            this.actualizeData();
        });
    }

    render() {
        const { data, tpData, isLoading, lastSave, pages, currentPage } = this.state;

        return (
            <Container fixed>
                <div className='wrap'>
                    <Card variant="outlined" className='card'>
                        <CardContent>
                            <h2 className='title'>Заполните таблицу ниже (Коми, линии)</h2>
                            <LineHeader />
                            {
                                (!isLoading && data) ? Object.keys(data).map((el, ind) => (
                                    <LineElement 
                                        key={ind} 
                                        id={data[el].id} 
                                        N_pp={data[el].N_pp} 
                                        N_dog={data[el].N_dog} 
                                        Name={data[el].Name} 
                                        Inv_number={data[el].Inv_number}
                                        Coords={data[el].Coords}
                                        TPlist={tpData}
                                        onChange={ (id, newCoords) => this.handleUpdate(id, data[el], newCoords) }
                                    />
                                )) : (
                                    <p style={{color: 'red'}}><b>Идёт загрузка...</b></p>
                                )
                            }

                            <Pagination count={pages} page={currentPage} siblingCount={8} onChange={this.handlePaginate} style={{marginTop: 30}} />

                            <p className='infoMsg'>Таблица сохраняется автоматически. {lastSave ? `Последнее сохранение: ${lastSave}` : ''}</p>
                        </CardContent>
                    </Card>
                </div>
            </Container>
        )
    }
};

export default LinesPage;
