import React, {Component, useState, useEffect, Fragment} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {SERVER_PATH, TABLE_ID} from '../../config/constants';
import './styles.scss';
import { filter } from 'lodash';


const BranchMenu = ({onAddPoint, onAddTP}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleAddPoint = () => {
        if (onAddPoint) onAddPoint();
        handleClose();
    };

    const handleAddTP = () => {
        if (onAddTP) onAddTP();
        handleClose();
    };
  
    return (
      <Fragment>
        <button aria-controls="tmenu" aria-haspopup="true" className='coordsRow__add' onClick={handleClick}>+</button>
        <Menu
          id="tmenu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
            <MenuItem onClick={handleAddPoint}>Добавить точку</MenuItem>
            <MenuItem onClick={handleAddTP}>Добавить ТП</MenuItem>
        </Menu>
      </Fragment>
    );
};

const RowMenu = ({onAddPoint, onAddTP}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAddPoint = () => {
        if (onAddPoint) onAddPoint();
        handleClose();
    };

    const handleAddTP = () => {
        if (onAddTP) onAddTP();
        handleClose();
    };
  
    return (
      <Fragment>
        <div className='coordsRow__button'>
            <Button
                aria-controls="rowmenu"
                aria-haspopup="true"
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleClick}
            >+ Добавить ещё</Button>
        </div>
        <Menu
          id="rowmenu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleAddPoint}>Добавить точку</MenuItem>
          <MenuItem onClick={handleAddTP}>Добавить ТП</MenuItem>
        </Menu>
      </Fragment>
    );
};

const CoordsRow = ({num, type = 'point', X, Y, TP, onChange, onPointAdd, onTPAdd, onRemove, isParent = true, children}) => {
    const [list, setList] = useState();
    const [inputValue, setInputValue] = React.useState('');

    const [_X, setX] = useState(0);
    const [_Y, setY] = useState(0);
    const [_TP, setTP] = useState();
    
    useEffect(() => {
        const tmp = localStorage.getItem('ACTUS_TPLIST');
        
        try {
            const _list = JSON.parse(tmp);
            if (_list) {
                setList(_list);
            }
        } catch (err) {
            console.error(err);
        }

        setX(X);
        setY(Y);
        setTP(TP);

        if (TP && TP.num) {
            setInputValue(TP.num);
        }
    }, [X, Y, TP, localStorage.getItem('ACTUS_TPLIST')]);

    const handleChange = (flag, val) => {
        if (flag === 'TP') {
            // console.log(val);
            onChange(num, 'TP', val);
        } else {
            let _val = val.replace(/\s+/, "");

            try {
                let inputValue = parseFloat(_val);
    
                if (!isNaN(inputValue)) {
                    _val = inputValue;
                } else if (val.length === 0) {
                    _val = val;
                } else {
                    alert('Введено некорректно значение: ', val);
                }
    
                if (flag === 'X') {
                    onChange(num, 'X', _val);
                } else {
                    onChange(num, 'Y', _val);
                }
            } catch (e) {
                console.error(e);
            }
        }
    };

    const handleRowAddPoint = () => {
        if (onPointAdd) onPointAdd(num);
    };
    const handleRowAddTP = () => {
        if (onTPAdd) onTPAdd(num);
    };
    const handleRemove = () => {
        if (onRemove) onRemove(num);
    };
    const handleInputValue = () => {

    };

    return (
        <div className='coordsRow'>
            <div className='coordsRow__col'>
                <div className='coordsRow__num'>{num}:</div>
                {
                    type === 'point' ? (
                        <Fragment>
                            <TextField 
                                label="X" 
                                type="number"
                                variant="outlined"
                                className='coordsRow__input'
                                size="small"
                                value={_X}
                                onChange={e => handleChange('X', e.target.value)}
                            />
                            <TextField 
                                label="Y" 
                                type="number"
                                variant="outlined"
                                className='coordsRow__input'
                                size="small"
                                value={_Y}
                                onChange={e => handleChange('Y', e.target.value)}
                            />
                        </Fragment>
                    ) : (list && list.length) ? (
                        <Autocomplete
                            options={list}
                            value={_TP}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            getOptionLabel={(option) => option.num ? option.num.toString() : ''}
                            renderInput={(params) => <TextField {...params} label="ТП №" variant="outlined" size="small" />}
                            onChange={(e, newVal) => handleChange('TP', newVal)}
                        />
                    ) : null
                }

                <div className='coordsRow__navigation'>
                    {
                        isParent ? (
                            <BranchMenu 
                                onAddPoint={() => handleRowAddPoint()}
                                onAddTP={() => handleRowAddTP()}
                            />
                        ) : null
                    }

                    <button className='coordsRow__remove' onClick={handleRemove}>✕</button>
                </div>
            </div>
            
            {
                children ? (
                    <div className='coordsRow__parent'>
                        { children }
                    </div>
                ) : null
            }

        </div>
    );
};

// <Autocomplete
// options={tpList}
// getOptionLabel={(option) => option.num.toString()}
// renderInput={(params) => <TextField {...params} label="ТП №" variant="outlined" size="small" />}
// />

const ObjectRow = ({
    id,
    N_pp,
    N_dog,
    Inv_number,
    Name,
    actusId,
    children,
}) => {
    const downloadFile = (url) => {
        const a = document.createElement('a');
        a.href = url;
        a.setAttribute("download", `${N_dog}_os.mif`);
        a.click();
    }

    const handleMIF = async () => {
        try {
            const resp = await fetch(`${SERVER_PATH}/objects/table/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: id,
                }),
            });

            if (!resp.ok) {
                const message = `An error has occured: ${resp.status}`;
                throw new Error(message);
            }
            
            const result = await resp.json();
            console.log(result);

            downloadFile(result.mif);
        } catch (error) {
            console.log('error', error);
        }
    };

    const handlePreview = () => {
        window.open(`https://territorytest.vvdev.ru/?type=secureZone3&id=${actusId}`, '_blank').focus();
    };

    return (
        <div className='obj' key={id}>
            <div className='objData'>
                <div className='objData__header'>Объект № <span>{N_dog}</span></div>
                <div className='objData__item'>
                    <b>№ п/п: </b>{N_pp}
                </div>
                <div className='objData__item'>
                    <b>Кад. номер: </b>{Inv_number}
                </div>
                <div className='objData__item'>
                    <b>Наименование: </b>{Name}
                </div>

                <div className='objData__btn'>
                    <Button variant="outlined" color="secondary" onClick={handleMIF}>Скачать .MIF</Button>
                    <Button variant="outlined" color="secondary" onClick={handlePreview}>Предпросмотр</Button>
                </div>
            </div>

            <div className='objInner'>
                <div className='objData__title'>Заполните координаты</div>
                
                <div className='objData__wrap'>
                    { children }
                </div>
            </div>
        </div>
    );
};

class DemoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            lastSave: null,
            currentPage: 1,
            pages: 1,
            editedList: {},
            tpData: {},
            data: {},
            search: '',
        };

        this.actualizeTP = this.actualizeTP.bind(this);
        this.renderList = this.renderList.bind(this);
        this.renderItems = this.renderItems.bind(this);
        this.actualizeData = this.actualizeData.bind(this);
        this.handlePaginate = this.handlePaginate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.saveData = this.saveData.bind(this);
    }

    actualizeData() {
        const {search} = this.state;
        let searchStr = `${SERVER_PATH}/objects?limit=10&page=${this.state.currentPage}`;

        if (search) {
            searchStr = `${SERVER_PATH}/objects?limit=10&page=1&N_dog=${search}`
        }

        fetch(searchStr, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then(result => {
                if (result.results) {
                    let data = result.results;
                    let rezObj = {};

                    data.forEach(el => {
                        rezObj[el.N_dog] = {
                            ...el,
                        };

                        if (!rezObj[el.N_dog]['Data'] || !rezObj[el.N_dog]['Data']['0'] || !rezObj[el.N_dog]['Data'][0]['items']) {
                            rezObj[el.N_dog]['Data'] = {
                                '0': {
                                    items: {},
                                    order: [],
                                },
                            };
                        }
                    });

                    this.setState({
                        data: rezObj,
                        pages: result.totalPages,
                        isLoading: false,
                    });
                }
            })
            .catch(error => console.log('error', error));
    }

    renderList() {
        const rez = [];
        const {data} = this.state;

        const incNumber = (num) => {
            if (num) {
                const arr = num.split(',');
                arr[arr.length - 1] = Number(arr[arr.length - 1])  + 1;
                return arr.join(',');
            } else {
                return '1';
            }
        };

        const handleAdd = (obj, col, type, num) => {
            const newData = { ...data };

            // console.log(obj, col, type, num);

            if (num) {
                newData[obj]['Data'][num] = {
                    items: {
                        [`${num},1`]: {
                            type: type,
                            value: type === 'point' ? {X: 0, Y: 0} : '',
                        },
                    },
                    order: [`${num},1`],
                };
            } else {
                const order = newData[obj]['Data'][col]['order'];
                const lastNum = order[order.length - 1];
                const nextNum = incNumber(lastNum);
    
                newData[obj]['Data'][col]['order'].push(nextNum);
    
                if (type === 'tp') {
                    newData[obj]['Data'][col]['items'][nextNum] = {
                        type: 'tp',
                        value: '',
                    };
                } else {
                    newData[obj]['Data'][col]['items'][nextNum] = {
                        type: 'point',
                        value: {X: 0, Y: 0},
                    };
                }
            }

            this.setState({
                data: {...newData},
            });
        };

        const numRecalc = (obj, col) => {
            const newData = { ...data };
            let num = '';

            if (col === '0') {
                num = '0';
            } else {
                num = `${col},0`;
            }

            if (newData[obj]['Data'][col].items && newData[obj]['Data'][col].order) {
                const tmpItems = { ...newData[obj]['Data'][col].items };
                const tmpOrder = [ ...newData[obj]['Data'][col].order ];
    
                newData[obj]['Data'][col].items = {};
                newData[obj]['Data'][col].order = [];
                
                tmpOrder.forEach((el, ind) => {
                    const inc = incNumber(num);
                    const old = tmpOrder[ind];
                    newData[obj]['Data'][col].order.push(inc);
                    newData[obj]['Data'][col].items[inc] = { ...tmpItems[old] };

                    if (newData[obj]['Data'][old]) {
                        const o = newData[obj]['Data'];
                        delete Object.assign(o, {[inc]: o[old] })[old];
                    }
                
                    num = inc;
                });
    
                this.setState({
                    data: {...newData},
                });
            }
        };

        const handleRemove = (obj, col, num) => {
            const newData = { ...data };

            const recursiveRemove = (num) => {
                if (newData[obj]['Data'][num]) {
                    // console.log(newData[obj]['Data'][num]);

                    newData[obj]['Data'][num].order.forEach(el => {
                        recursiveRemove(el);
                    });

                    delete newData[obj]['Data'][num];
                }

                
                const index = newData[obj]['Data'][col].order.indexOf(num);
                if (index > -1) newData[obj]['Data'][col].order.splice(index, 1);
                delete newData[obj]['Data'][col].items[num];

                if ((col !== '0') && (Object.keys(newData[obj]['Data'][col].items).length === 0) && (newData[obj]['Data'][col].order.length === 0)) {
                    delete newData[obj]['Data'][col];
                }

                // numRecalc(obj, col);
            };

            console.log(obj, col, num);
            recursiveRemove(num);

            this.setState({
                data: {...newData},
            }, () => {
                this.handleUpdate(newData[obj]['id'], newData[obj], newData[obj]['Data']);
            });
        };

        const handleChange = (obj, col, num, flag, val) => {
            // console.log(col, num, flag, val);
            const newData = { ...data };

            if (flag && val) {
                if (flag === 'X') {
                    newData[obj]['Data'][col].items[num].value.X = val;
                } else if (flag === 'Y') {
                    newData[obj]['Data'][col].items[num].value.Y = val;
                } else if (flag === 'TP') {
                    newData[obj]['Data'][col].items[num].value = val;
                }
            }

            this.setState({
                data: {...newData},
            }, () => {
                this.handleUpdate(newData[obj]['id'], newData[obj], newData[obj]['Data']);
            });
        };

        for (let obj in data) {
            const el = data[obj];

            rez.push(
                <ObjectRow
                    id={el.id}
                    key={el.id}
                    N_pp={el.N_pp}
                    N_dog={el.N_dog}
                    Inv_number={el.Inv_number}
                    actusId={el.actusId}
                    Name={el.Name}
                >
                    {
                        this.renderItems(
                            obj,
                            el['Data'], 
                            '0',
                            (col, num) => handleAdd(obj, col, 'point', num),
                            (col, num) => handleAdd(obj, col, 'tp', num),
                            (col, num) => handleRemove(obj, col, num),
                            (col, num, flag, val) => handleChange(obj, col, num, flag, val),
                        )
                    }
                </ObjectRow>
            );
        }

        return rez;
    }

    renderItems(obj, Data, col, onPointAdd, onTPAdd, onRemove, onChange) {
        const rez = [];

        const handleRowAddPoint = () => {
            if (onPointAdd) onPointAdd(col);
        };
        const handleRowAddTP = () => {
            if (onTPAdd) onTPAdd(col);
        };
        const handleBranchAddPoint = (col, num) => {
            if (onPointAdd) onPointAdd(col, num);
        };
        const handleBranchAddTP = (col, num) => {
            if (onTPAdd) onTPAdd(col, num);
        };
        const handleRemove = (col, num) => {
            if (onRemove) onRemove(col, num);
        };
        const handleChange = (col, num, flag, val) => {
            if (onChange) onChange(col, num, flag, val);
        };

        Data[col].order.forEach((el, ind) => {
            const item = Data[col].items[el];
            const isParent = !!Data[el];
            const params = {};

            if (item.type === 'point') {
                params.X = item.value.X;
                params.Y = item.value.Y;
                params.type = 'point';
            } else {
                params.TP = item.value;
                params.type = 'tp';
            }

            if (isParent) {
                rez.push(
                    <CoordsRow 
                        key={`${col}.${el}`}
                        num={el}
                        onPointAdd={(num) => handleBranchAddPoint(col, num)}
                        onTPAdd={(num) => handleBranchAddTP(col, num)}
                        onRemove={(num) => handleRemove(col, num)}
                        onChange={(num, flag, val) => handleChange(col, num, flag, val)}
                        isParent={!isParent}
                        {...params}
                    >
                        { this.renderItems(obj, Data, el, onPointAdd, onTPAdd, onRemove, onChange) }
                    </CoordsRow>
                );
            } else {
                rez.push(
                    <CoordsRow
                        key={`${col}.${el}`}
                        num={el} 
                        onPointAdd={(num) => handleBranchAddPoint(col, num)}
                        onTPAdd={(num) => handleBranchAddTP(col, num)}
                        onRemove={(num) => handleRemove(col, num)}
                        onChange={(num, flag, val) => handleChange(col, num, flag, val)}
                        isParent={!isParent}
                        {...params} 
                    />
                );
            }

            if (ind === Data[col].order.length - 1) {
                rez.push(
                    <RowMenu
                        key={`rowMenu.${col}.${el}`}
                        onAddPoint={() => handleRowAddPoint()}
                        onAddTP={() => handleRowAddTP()}
                    />
                );
            }
        });

        if ((col === '0') && Data[col].order.length === 0) {
            rez.push(
                <RowMenu
                    key={`rowMenu.${col}.0`}
                    onAddPoint={() => handleRowAddPoint()}
                    onAddTP={() => handleRowAddTP()}
                />
            );
        }

        return rez;
    }

    actualizeTP() {
        fetch(`${SERVER_PATH}/tables?limit=100000&sortBy=num`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then(result => {
                if (result.results) {
                    const rez = [];

                    result.results.forEach(tp => {
                        if (tp.data && tp.data.length > 1) {
                            rez.push({
                                id: tp.id,
                                num: tp.num,
                            });
                        }
                    });

                    this.setState({
                        tpData: rez,
                    }, () => {
                        localStorage.setItem('ACTUS_TPLIST', JSON.stringify(this.state.tpData));
                    });
                }
            })
            .catch(error => console.log('error', error));
    }

    handlePaginate(e, page) {
        this.setState({
            currentPage: page,
            isLoading: true,
            data: {},
        }, () => {
            this.actualizeData();
        });
    }

    handleUpdate(id, element, newData) {
        this.setState({
            isEdited: true,
            editedList: {
                ...this.state.editedList,
                [id]: {
                    N_dog: element.N_dog,
                    N_pp: element.N_pp,
                    Name: element.Name,
                    Data: { ...newData },
                },
            },
        }, () => {
            console.log(this.state.data);
        });
    }

    async saveData() {
        if (this.state.isEdited) {
            const { editedList } = this.state;

            for (let el of Object.keys(editedList)) {
                try {
                    console.log(editedList[el]);
                    console.log(JSON.stringify(editedList[el]));
                    console.log(JSON.parse( JSON.stringify(editedList[el]) ))

                    const resp = await fetch(`${SERVER_PATH}/objects/${el}/`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(editedList[el]),
                    });

                    if (!resp.ok) {
                        const message = `An error has occured: ${resp.status}`;
                        throw new Error(message);
                    }
                    
                    // const result = await resp.json();
                    // console.log(result);
                } catch (error) {
                    console.log('error', error);
                }
            }

            this.setState({
                isEdited: false,
                lastSave: new Date().toLocaleString(),
                editedList: {},
            }, () => {
                this.actualizeData();
            });
        }
    }

    componentDidMount() {
        this.actualizeData();
        this.actualizeTP();
    }

    render() {
        const {isLoading, data, pages, currentPage, lastSave, isEdited, search} = this.state;

        const handleSearch = (val) => {
            this.setState({
                search: val,
            }, () => {
                this.actualizeData();
            });
        };

        return (
            <Fragment>
                <div className='wrap'>
                    <div className='objectsContainer'>

                        <TextField 
                            label="Поиск по договору" 
                            variant="outlined"
                            className='coordsRow__input'
                            size="small"
                            value={search}
                            onChange={e => handleSearch(e.target.value)}
                        />

                        <Pagination count={pages} page={currentPage} siblingCount={8} onChange={this.handlePaginate} style={{marginBottom: 30}} />
                        <p className='infoMsg'>Не забудьте сохранить изменения в таблице!</p>

                        { 
                            (!isLoading && data) ? this.renderList() : <p style={{color: 'red'}}><b>Идёт загрузка...</b></p>
                        }

                        {
                            (pages > 1) && !isLoading ? (
                                <Fragment>
                                    <Pagination count={pages} page={currentPage} siblingCount={8} onChange={this.handlePaginate} style={{marginTop: 30}} />
                                    <p className='infoMsg'>Не забудьте сохранить изменения в таблице!</p>
                                </Fragment>
                            ) : null
                        }
                    </div>
                    
                </div>

                {
                    isEdited ? (
                        <div className='saveZone'>
                            <p className='saveZone__info'>Не забудьте сохранить изменения</p>
                            
                            <Button className='saveZone__btn' variant="contained" color="secondary" onClick={this.saveData}>Сохранить изменения</Button>
                            
                            {
                                lastSave ? (
                                    <p className='saveZone__stat'>Последнее сохранение {lastSave}</p>
                                ) : null
                            }
                        </div>
                    ) : null
                }
                
            </Fragment>
        )
    }
}

export default DemoPage;
